import React from 'react'

import './navBar.css'
import close_ico from '../../image/x.svg'

export default function NavBar(props: any) {

    const open = {
        right: 0,
        borderRadius: 0,
        width: "100%",
        opacity: 1,
        height: "100vh"
    }

    const close = {
        right: 0,
        borderBottomLeftRadius: "50%",
        width: 0,
        opacity: 0,
        height: 0
    }
    return (
        <div className={"content_navbar"} style={props.open ? open : close}>
            <img src={close_ico as string} alt="close icon" onClick={() => props.onChange()}/>
            <h1>Portfolio - Corentin DEPRECQ</h1>
            <div className={"content_item_nav"}>
                <h2>MENU</h2>
                <a onClick={() => props.onChange()} href="#top">En Haut</a>
                <a onClick={() => props.onChange()} href="#moi">Moi</a>
                <a onClick={() => props.onChange()} href="#passion">Mes passions</a>
                <a onClick={() => props.onChange()} href="#competence">Mes compétences</a>
                <a onClick={() => props.onChange()} href="#histo">Mon parcours</a>
                <a onClick={() => props.onChange()} href="#real">Mes réalisations</a>
                <a onClick={() => props.onChange()} href="#contact">Contact</a>
                <p>Corentin DEPRECQ © 2021</p>
            </div>
        </div>
    )
}