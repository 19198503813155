import portfolio from "../image/portfolio.png"
import portfolio2 from "../image/portfolio2.png"
import dameDePique1 from "../image/damePique1.webp"
import dameDePique2 from "../image/damePique3.webp"
import dameDePique3 from "../image/damePique2.webp"
import web1 from "../image/web1.webp"
import web2 from "../image/web2.webp"
import web3 from "../image/web3.webp"
import ci from "../image/ci.png"
import terminal from "../image/terminal-box-svgrepo-com.svg"
import web from "../image/web-svgrepo-com.svg"

export interface Travail {
    titre: string,
    mainIcon: any,
    content: string,
    type: string,
    images: Array<{
        name: string,
        image: any
    }>,
    liste: any
    langue: any
    outil: any
}

export const getData = (): Array<Travail> => {
    return [
        {
            mainIcon: web,
            "images": [
                {
                    "name": "Portfolio",
                    "image": portfolio
                },
                {
                    "name": "React",
                    "image": portfolio2
                },
                {
                    "name": "CI/CD",
                    "image": ci
                }
            ],
            "titre": "Création de mon Portfolio",
            "content": "Création d’un site web me présentant Conception et codage du site (Figma, HTML, Css, JavaScript)\nHebergement du site statique sur AWS",
            "type": "Développement web",
            "liste": [
                "Conception et codage du site (Figma, HTML, Css, JavaScript)",
                "Hebergement du site statique sur AWS"
            ],
            "langue": [
                "Html & Css",
                "JavaScript"
            ],
            "outil": [
                "Figma",
                "ReactJS",
                "AWS S3"
            ]
        },
        {
            mainIcon: terminal,
            "images": [
                {
                    "name": "Dame de pique",
                    "image": dameDePique1
                },
                {
                    "name": "Java",
                    "image": dameDePique2
                },
                {
                    "name": "Ligne de commande",
                    "image": dameDePique3
                }
            ],
            "titre": "Développement jeux",
            "content": "Création d’un jeu “Dame de Pique” avec une équipe de 4 personnes Rôle : directeur de la maitrise d’ouvrage\nGestion d’une équipe et du projet\nCodage de l’application (Java)\nGestion et rédaction du plan et du compte rendu du projet",
            "type": "Développement applicatif",
            "liste": [
                "Rôle : directeur de la maitrise d’ouvrage",
                "Gestion d’une équipe et du projet",
                "Codage de l’application (Java)",
                "Gestion et rédaction du plan et du compte rendu du projet",
                "Conception de diagramme (Gantt, UML)",
                "Présentation orale devant un jury",
                "Relation avec le client et identification du besoin"
            ],
            "langue": [
                "Java"
            ],
            "outil": []
        },
        {
            mainIcon: web,
            "images": [
                {
                    "name": "Web",
                    "image": web1
                },
                {
                    "name": "Vanilla",
                    "image": web2
                },
                {
                    "name": "Css",
                    "image": web3
                }
            ],
            "titre": "Projet Web",
            "content": "Création d’un site web vitrine d’achat de matériel informatiqueGestion d’une équipe Codage du site (HTML, CSS, JavaScript) Gestion et rédaction d’un cahier des charge",
            "type": "Développement web",
            "liste": [
                "Gestion d’une équipe",
                "Codage du site (HTML, CSS, JavaScript)",
                "Gestion et rédaction d’un cahier des charges"
            ],
            "langue": [
                "HTML",
                "Css",
                "JavaScript"
            ],
            "outil": [
                "Figma"
            ]
        }
    ]
}
