import React, {useEffect, useState} from 'react'
import './bandeauFin.css'
import image from '../../image/logo.png'

export default function BandeauFin() {

    const [currentYear, setCurrentYear] = useState(2021)

    useEffect(() => {
        const date = new Date()

        setCurrentYear(date.getUTCFullYear())
    }, []);

    return (
        <div className={"container_bf"}>

            <img src={image as string} alt="Icon site web"/>

            <h2>Sursum Corda.</h2>

            <p>Corentin DEPRECQ © 2021/{currentYear}</p>

        </div>
    )
}