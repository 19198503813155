import React from "react";

import programming2 from '../../image/programing2.png'

import './home.scss'

export default function Home() {

    return (<div className={"container_home"} id={"top"}>

            <div className={"content"}>

                <img src={programming2 as string} alt="un programmeur" className={"image_programming"}/>
                <h1 className={"titre"} id={"titre"}>Hello, Je suis <div className={"mon_nom"}>Corentin</div> 👋</h1>
            </div>
        </div>)
}
