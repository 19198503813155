import React from "react";
import './scrollDown.css'

export default function ScrollDown() {
    return (
        <div className={"contentMouse"}>
            <div className={"mouse"}/>
            <p>Scroll down</p>
        </div>
    )
}