import React from 'react'
import './parcours.css'

import data from '../../Data/data_parcours.json'

const Ligne = (props) => {
    if (props.num % 2 === 0) {
        return <div className={"item_parcour"}>
            <div>
                <h2>{props.data.annee}</h2>
                <h3>{props.data.titre}</h3>
                <p>{props.data.content}</p>
                <ul>
                    {props.data.liste.map((e, count) => {
                        return <li key={count}>- {e}</li>
                    })}
                </ul>
            </div>
            <div/>
        </div>
    } else {
        return <div className={"item_parcour"}>
            <div/>
            <div>
                <h2>{props.data.annee}</h2>
                <h3>{props.data.titre}</h3>
                <p>{props.data.content}</p>
                <ul>
                    {props.data.liste.map((e, count) => {
                        return <li key={count}>- {e}</li>
                    })}
                </ul>
            </div>
        </div>
    }
}

export default function Parcours() {
    return (
        <div>
            <h1 className={"titre"}>Comment j'en suis arrivé là ?</h1>
            <div className={"separator"}/>

            <div className={"content_parcour"}>
                {data.map((e, count) => {
                    return <Ligne key={count} num={count} data={e}/>
                })}
            </div>
        </div>
    )
}