import React, {useEffect, useState} from "react";

import './cursor.css'

export default function Cursor() {

    const [cursor, setCursor] = useState<HTMLElement>()
    const [h1, setH1] = useState<HTMLElement>()

    useEffect(() => {
        const c = document.getElementById('cursor')!;
        const t = document.getElementById('titre')!;
        setCursor(c)
        setH1(t)
    }, [])


    useEffect(() => {
        if (cursor !== undefined) {
            document.addEventListener('mousemove', e => {
                cursor.style.top = (e.pageY - (cursor.offsetWidth / 2)) + "px"
                cursor.style.left = (e.pageX - (cursor.offsetHeight / 2)) + "px"
            })
        }
    }, [cursor])

    useEffect(() => {
        if (h1 !== undefined && cursor !== undefined) {
            h1.addEventListener('mouseenter', _ => {
                cursor.classList.add("hoverContent")
            })
            h1.addEventListener('mouseout', _ => {
                cursor.classList.remove("hoverContent")
            })
        }
    }, [cursor, h1])


    return (
        <div className={"cursor"} id={"cursor"}>
            <div className={"content_cursor"}/>
        </div>
    )
}