import React from 'react'
import "./goTop.css"

import icon from "../../../image/arrow-up-circle.svg"

export default function GoTop() {
    return (
        <a id={"topItemId"} href={"#top"} className={"container_goTop"}>
            <img src={icon as string} alt="icon top"/>
        </a>
    )
}