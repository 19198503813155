import React, {useEffect, useRef, useState} from "react";
import './contact.scss'
import x from '../../image/x.svg'
import play from "../../image/play-alt-svgrepo-com.svg"

interface MethodModel {
    name: string;
    response: string;
}

interface ConsolePrint {
    attribut: Attribut;
    date: Date,
    time: string
}

interface Attribut {
    id: string
    content: JSX.Element
    name: string
    comment: string
}

export default function Contact() {
    return (
        <div className={"content_contact"}>

            <h1 className={"titre"}>Me contacter</h1>
            <div className={"separator"}/>

            <Contenu/>
        </div>
    )
}

const Contenu = () => {

    const [current, setCurrent] = React.useState<Array<ConsolePrint>>([]);

    const [lignes, setLignes] = useState<number>(0);

    const [start, setStart] = useState<Array<number>>([]);

    const attributs: Array<Attribut> = [
        {
            id: "numero",
            content: <span className={"string"}>06 26 22 77 60</span>,
            name: "getNumero",
            comment: "Récupération du numéro de téléphone."
        },
        {
            id: "mail",
            content: <span className={"string"}>corentin.deprecq@protonmail.com</span>,
            name: "getEmail",
            comment: "Récupération de l'adresse mail."
        },
        {
            id: "gitlab",
            content: <span>
                <a className={"link"} href="https://gitlab.com/corentin.deprecq1"
                   target='_blank' rel="noreferrer">https://gitlab.com/corentin.deprecq1</a>
            </span>,
            name: "getGitlab",
            comment: "Récupération du lien vers le gitlab."
        },
        {
            id: "linkedin",
            content: <span>
                <a className={"link"} href="https://www.linkedin.com/in/corentin-deprecq/"
                   target='_blank' rel="noreferrer">https://www.linkedin.com/in/corentin-deprecq/</a>
            </span>,
            name: "getLinkedin",
            comment: "Récupération du lien vers le linkedin."
        },
    ];

    useEffect(() => {
        const start = Array.from({length: attributs.length}, (_, i) => {
            return 8 + ((attributs.length - 1) * 2) + (7 * i)
        })

        setStart(start)
        setLignes(attributs.length * 2 + attributs.length * 7 + 3)
    }, [attributs.length]);

    const startCommand = (number: number) => {

        const tmp: ConsolePrint = {
            attribut: attributs[number],
            date: new Date(),
            time: String(Math.floor(Math.random() * 3)),
        }
        setCurrent([...current, tmp])
    }

    const containerRef = useRef(null);

    const scrollToBottom = () => {
        if (containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [current]);

    return (
        <div className={"contact"}>
            <div className={"top_item_contact"}>
                <div className={"left_contact"}>
                    <span>Contact.ts</span>
                    <img src={x} alt={"Fermer"}/>
                </div>
                <div className={"close_panel"}>
                    <div className={"close_item_1"}/>
                    <div className={"close_item_2"}/>
                    <div className={"close_item_3"}/>
                </div>
            </div>

            <div className={"code"}>
                <div className={"numbers"}>
                    {Array.from({length: lignes}, (_, count) => (
                        <div key={count} className={"line"}>
                            <div>{count + 1}</div>
                            <div className={"start"}>{start.includes(count + 1) ?
                                <img onClick={() => startCommand(start.indexOf(count + 1))} src={play}
                                     alt="play icon"/> : ""}</div>
                        </div>
                    ))}
                </div>
                <div className={"lignes"}>
                    <div className={"line"}><span className={"identifier"}>export class</span> <span
                        className={"characters"}>Contact</span> <span className={"acolade"}>&#123;</span></div>

                    <Attributs attributs={attributs}/>

                    <Methods attributs={attributs}/>
                    <div><span className={"acolade"}>&#125;</span></div>
                </div>
            </div>
            <div className={"console"}>
                <div className={"top_item_contact"}>
                    <div className={"left_contact"}>
                        <div>Console</div>
                        <img src={x} alt={"Fermer"} onClick={() => setCurrent([])}/>
                    </div>
                </div>
                <div className={"content_console"} ref={containerRef}>
                    {current.length === 0 ? <span className={"commentaire"}>La console est vide...</span> : ""}
                    {
                        current.map((item: ConsolePrint, count: number) => {
                            return (
                                <div>
                                    <div key={count} className={"line"}>
                                        <span className={"string"}>[Contact] 38192 -</span>
                                        <Espace nb={1}/>
                                        <span className={"datetime"}>{item.date.toLocaleString()}</span>
                                        <Espace nb={3}/>
                                        <span className={"string"}>LOG</span>
                                        <Espace nb={1}/>
                                        <span className={"characters"}>[ContactController]</span>
                                        <Espace nb={1}/>
                                        <span className={"string"}>Mapped &#123;/{item.attribut.name}, GET&#125;</span>
                                        <Espace nb={1}/>
                                        <span className={"characters"}>+{item.time}ms </span>
                                    </div>
                                    <div key={count} className={"line"}>
                                        <span className={"string"}>[Contact] 38192 -</span>
                                        <Espace nb={1}/>
                                        <span className={"datetime"}>{item.date.toLocaleString()}</span>
                                        <Espace nb={3}/>
                                        <span className={"string"}>LOG</span>
                                        <Espace nb={1}/>
                                        <span className={"characters"}>[ContactController]</span>
                                        <Espace nb={1}/>
                                        <span>{item.attribut.content}</span>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

const Methods = (props: { attributs: Array<Attribut> }) => {
    return <div>

        {props.attributs.map((attr: any, count) => {
            return (
                <div key={count}>
                    <Blank/>
                    <Commentaire commentaire={attr.comment}/>
                    <Method response={attr.id} name={attr.name}/>
                </div>
            )
        })}
    </div>;
}

const Commentaire = (props: { commentaire: string }) => {
    return (
        <div>

            <div className={"line"}>
                <Espace nb={4}/>
                <span className={"commentaire"}>/**</span>
            </div>
            <div className={"line"}>
                <Espace nb={5}/>
                <span className={"commentaire"}>*</span>
                <Espace nb={1}/>
                <span className={"commentaire"}>{props.commentaire}</span>
            </div>
            <div className={"line"}>
                <Espace nb={5}/>
                <span className={"commentaire"}>*/</span>
            </div>
        </div>

    )
}

const Attributs = (props: { attributs: Array<Attribut> }) => {

    return (
        <div>
            {props.attributs.map((attrib, count) => (<div key={count}>
                <Blank/>
                <div className={"line"}>
                    <Espace nb={4}/><span className={"identifier"}>private</span><Espace nb={1}/>
                    <span className={"variable"}>{attrib.id}</span><Espace nb={1}/>
                    <span className={"operator"}>=</span><Espace nb={1}/>
                    <span className={"string"}>"{attrib.content}"</span>
                    <span className={"operator"}>;</span>
                </div>
            </div>))}
        </div>
    )
}

const Espace = (props: { nb: number }) => {
    return <span>{
        Array.from({length: props.nb}, (_, count) => (
            <span key={count}>&nbsp;</span>
        ))
    }</span>
}

const Method = (method: MethodModel) => {
    return (
        <div>
            <div className={"line"}>
                <Espace nb={4}/>
                <span className={"identifier"}>private</span>
                <Espace nb={1}/>
                <span className={"methodName"}>{method.name}</span>
                <span className={"characters"}>()</span>
                <Espace nb={1}/>
                <span className={"acolade"}>&#123;</span>
            </div>
            <div className={"line"}>
                <Espace nb={8}/>
                <span className={"identifier"}>return</span>
                <Espace nb={1}/>
                <span className={"identifier"}>this</span>
                <span className={"operator"}>.</span>
                <span className={"variable"}>{method.response}</span>
                <span className={"operator"}>;</span>
            </div>
            <div className={"line"}>
                <Espace nb={4}/><span className={"acolade"}>&#125;</span>
            </div>
        </div>
    );
}

const Blank = () => {
    return <div className={"line"}>&nbsp;</div>;
}