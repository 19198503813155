import {BrowserRouter, Route, Routes} from "react-router-dom";
import Container from "./component/Container";
import Cursor from "./component/Utils/Cursor/Cursor";
import React, {useEffect} from "react";
import GoTop from "./component/Utils/GoTop/GoTop";
import bg from "./image/bg.svg"
import "./App.scss"

function App() {

    useEffect(() => {
        const top = document.getElementById("topItemId");
        document.addEventListener("scroll", (_) => {
            if (window.scrollY > 500) {
                top.classList.add('visible')
                top.classList.remove('notVisible')
            } else {
                top.classList.remove('visible')
                top.classList.add('notVisible')
            }
        })
    }, [])

    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Container/>}/>
                </Routes>
            </BrowserRouter>
            <img className={"back"} src={bg as string} alt="backgroud"/>
            <Cursor/>
            <GoTop/>
        </div>
    );
}

export default App;
