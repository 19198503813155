import React, {useState} from 'react'
import './travaux.scss'
import close from '../../image/x.svg'
import {getData, Travail} from "../../Data/DataTravaux";

export default function Travaux() {

    return (
        <div className="travaux">
            <h1 className={"titre"}>Voyez par vous-même</h1>
            <div className={"separator"}/>

            <div className="content_travaux">
                {getData().map((e, count) => {
                    return (
                        <TravailContent data={e} key={count}/>
                    )
                })}
            </div>

        </div>
    )
}

interface CustomProps {
    data: Travail
}
const TravailContent = ({data}: CustomProps) => {

    const [item, setItem] = useState<number>(0)

    const handleChange = (nb: number) => {
        setItem(nb)
    }

    return (
        <div className={"travail"}>
            <div className={"img_travail"}>
                {
                    data.images.map((img, count) => {
                        return (
                            <img src={img.image} alt={img.name} key={count} className={item === count ? "imgSelected" : ""}/>
                        )
                    })
                }
            </div>
            <div className={"right_travail"}>
                <div className={"top_travail"}>
                    <img className={"img_top_travail"} src={data.mainIcon} alt="close icon"/>
                    <h2>{data.type}</h2>
                </div>
                <div className={"titre_travail"}>
                    <h1>{data.titre}</h1>
                    <p>{data.content}</p>
                </div>
                <div className={"item_travail"}>
                    {
                        data.images.map((e, count) => {
                            return (
                                <div key={count} className={"item_travail_item " + (item === count ? "select" + count : "")} onClick={() => handleChange(count)}>
                                    <div className={"img_item_travail"}>
                                        <img src={close as string} alt="close icon"/>
                                    </div>
                                    <h2>{e.name}</h2>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}