import React from "react";
import pro from '../../image/moni.svg'
import camp from '../../image/camping-svgrepo-com.svg'
import sport from '../../image/sport.svg'
import './passion.scss'

export default function Passion() {
    return (
        <div>
            <h1 className={"titre"}>Je suis passionné par</h1>
            <div className={"separator"}/>
            <div className={"content_passion"}>
                <div>
                    <img src={camp as string} alt="programming"/>
                    <h2>Aventure</h2>
                    <p>J'aime profiter de la nature...</p>
                </div>
                <div className={"special"}>
                    <img src={pro as string} alt="programming"/>
                    <h2>Développement web</h2>
                    <p>Je programme depuis très jeune...</p>
                </div>
                <div>
                    <img src={sport as string} alt="programming"/>
                    <h2>Sport</h2>
                    <p>J'aime aussi me défouler...</p>
                </div>
            </div>
        </div>
    )
}