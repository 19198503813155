import React, {useEffect, useState} from "react";
import "./time.css"

export default function Time() {

    const [heure, setHeure] = useState(0)
    const [min, setMin] = useState(0)
    const [sec, setSec] = useState(0)

    useEffect(() => {
        setInterval(() => {
            const date = new Date()
            setHeure(date.getHours())
            setMin(date.getMinutes())
            setSec(date.getSeconds())
        })
    }, [])

    return (
        <div className={"container_time"}>
            <div className={"clock"}>
                <div className={"content_line_time content_h"}
                     style={{transform: `translate(-50%, -50%) rotate(${heure % 12 * 30}deg)`}}>
                    <div className={"h"}/>
                    <div/>
                </div>
                <div className={"content_line_time content_m"}
                     style={{transform: `translate(-50%, -50%) rotate(${min * 6}deg)`}}>
                    <div className={"m"}/>
                    <div/>
                </div>
                <div className={"content_line_time content_s"}
                     style={{transform: `translate(-50%, -50%) rotate(${sec * 6}deg)`}}>
                    <div className={"s"}/>
                    <div/>
                </div>
                <div className={"time_num top"}>12</div>
                <div className={"time_num right"}>3</div>
                <div className={"time_num bottom"}>6</div>
                <div className={"time_num left"}>9</div>
                <div className={"step step1"}>
                    <div></div>
                    <div></div>
                </div>
                <div className={"step step2"}>
                    <div></div>
                    <div></div>
                </div>
                <div className={"step step3"}>
                    <div></div>
                    <div></div>
                </div>
                <div className={"step step4"}>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    )
}