import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

const doc: HTMLElement = document.getElementById('root')!;
const root = ReactDOM.createRoot(doc);
root.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>
);