import React, {useState} from 'react'
import Home from "./0_HomePage/Home";
import Moi from "./1_Moi/Moi";
import ScrollDown from "./Utils/ScrollDown/ScrollDown";
import menu from '../image/menu.svg'

import './NavBar/navBar.css'
import NavBar from "./NavBar/NavBar";

export default function Container() {

    const [open, setOpen] = useState(false)

    const change = () => {
        setOpen(!open)
    }

    return (
        <div>
            <Home/>
            <ScrollDown/>
            <Moi/>
            <NavBar open={open} onChange={change}/>
            <div className={"btn_menu"} onClick={change} style={{top: open ? "-50px" : "10px"}}>
                <img src={menu as string} alt="icon menu"/>
            </div>
            {/*<Chat/>*/}
        </div>
    )
}
