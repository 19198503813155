import React from 'react'
import './competence.scss'

import full_star from '../../image/star_pleine.svg'
import empty_star from '../../image/star_vide.svg'


import html from '../../image/html&css.png'
import js from '../../image/js.svg'
import java from '../../image/java.png'
import sql from '../../image/sql.svg'
import git from '../../image/git.svg'
import jet from '../../image/jetbrains.svg'
import react from '../../image/react.svg'
import spring from '../../image/spring.svg'
import linux from '../../image/linux.svg'
import k8s from '../../image/k8s.png'
import angular from '../../image/angular.png'
import dnet from '../../image/DotNet.png'
import neo from '../../image/neo4j.svg'
import python from '../../image/python.ico'
import vue from '../../image/vue.svg'
import github from '../../image/github.svg'
import flutter from '../../image/flutter.svg'
import elixir from '../../image/elixir.svg'
import docker from '../../image/docker.svg'
import {styled, Tooltip, tooltipClasses, Zoom} from "@mui/material";

const Star = (props) => {

    /**
     * Fonction qui renvoie le nombre d etoile en focntion du parametre
     * @returns {*[]}
     */
    const getStar = () => {
        let tmp = []

        for (let i = 0; i < props.nb; i++) {
            tmp.push(<img src={full_star} key={i} alt="étoile pleine"/>)
        }

        for (let i = 0; i < 5 - props.nb; i++) {
            tmp.push(<img src={empty_star} key={5 - i} alt="étoile vide"/>)
        }

        return tmp
    }

    return <div className={"item_star"}>{getStar()}</div>
}

export default function Competence() {

    const getTabSort = (tab) => {
        return tab.sort((a, b) => a.star < b.star)
    }

    const listIcons = [
        {
            img: html,
            tooltip: "HTML",
            stars: 5
        },
        {
            img: js,
            tooltip: "Java Script",
            stars: 5
        },
        {
            img: git,
            tooltip: "Git",
            stars: 5
        },
        {
            img: jet,
            tooltip: "Jetbrains",
            stars: 5
        },
        {
            img: react,
            tooltip: "React",
            stars: 5
        },
        {
            img: linux,
            tooltip: "Linux",
            stars: 5
        },
        {
            img: java,
            tooltip: "Java",
            stars: 5
        },
        {
            img: sql,
            tooltip: "SQL",
            stars: 5
        },
        {
            img: spring,
            tooltip: "Spring",
            stars: 5
        },
        {
            img: neo,
            tooltip: "Neo4j",
            stars: 5
        },
        {
            img: k8s,
            tooltip: "Kubernetes",
            stars: 5
        },
        {
            img: python,
            tooltip: "Python",
            stars: 5
        },
        {
            img: angular,
            tooltip: "Angular",
            stars: 5
        },
        {
            img: dnet,
            tooltip: "HTML",
            stars: 5
        },
        {
            img: vue,
            tooltip: "Vue JS",
            stars: 5
        },
        {
            img: github,
            tooltip: "Github",
            stars: 5
        },
        {
            img: flutter,
            tooltip: "Flutter",
            stars: 5
        },
        {
            img: elixir,
            tooltip: "Elixir",
            stars: 5
        },
        {
            img: docker,
            tooltip: "Docker",
            stars: 5
        }
    ]

    return (
        <div>
            <h1 className={"titre"}>Voici ce que je sais faire</h1>
            <div className={"separator"}/>

            <div className={"content_competence"}>

                {
                    listIcons.map((item, count) => {
                        return (
                            <LightTooltip TransitionComponent={Zoom} key={count} title={item.tooltip}>
                                <div className={"item_competence"}>
                                    <img src={item.img} alt="icon"/>
                                </div>
                            </LightTooltip>
                        )
                    })
                }
            </div>
        </div>
    )
}

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));