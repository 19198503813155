import React from 'react'
import './pastille.css'

interface CustomProps {
    text: string,
    id: string
}

export default function Pastille(props: CustomProps) {

    return (
        <div className={"container_past animate-top"} id={props.id}>
            <p>{props.text}</p>
        </div>
    )
}